body {
  margin: 0;
  padding: 0px 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
a {
  text-decoration: none;
}
/* a:visited {
  color: inherit;
} */
a:hover {
  text-decoration: underline;
}
/* a:active {
  color: initial;
} */
a:-webkit-any-link {
  color: inherit;
}
